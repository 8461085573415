// ----------------------------- Fonts -----------------------------

$font-family: 'helvetica-regular';
$font-family-bold: 'helvetica-bold';
$font-family-light: 'helvetica-light';
$font-family-medium: 'helvetica-medium';

// ----------------------------- Colors -----------------------------
$color-primary: #c23b33;
$color-primary-rgb: 194,59,51;
$color-primary-rgba: rgba(194,59,51, 0.05);
$color-primary-contrast: #ffffff;
$color-primary-contrast-rgb: 255,255,255;
$color-primary-shade: #ab342d;
$color-primary-tint: #fff5f4;

$color-secondary: #8e2a2a;
$color-secondary-rgb: 142,42,42;
$color-secondary-contrast: #ffffff;
$color-secondary-contrast-rgb: 255,255,255;
$color-secondary-contrast-rgba: rgba(255, 255, 255, 0.4);
$color-secondary-shade: #7d2525;
$color-secondary-tint: #993f3f;
$color-secondary-light: #EE907B;

$color-tertiary: #9f2842;
$color-tertiary-rgb: 159,40,66;
$color-tertiary-shade: #930800;
$color-tertiary-tint: #a93e55;

$color-danger: #eb445a;
$color-danger-rgb: 235,68,90;
$color-danger-contrast: #ffffff;
$color-danger-contrast-rgb: 255,255,255;
$color-danger-shade: #cf3c4f;
$color-danger-tint: #ed576b;

$color-dark:  #343434;
$color-dark-2: #555555;

$color-medium: #76777a;
$color-medium-rgb: 118,119,122;
$color-medium-rgba: rgba(118,119,122,0.2);
$color-medium-shade: #646464;
$color-medium-shade-rgb: 100,100,100;
$color-medium-shade-rgba: rgba(100,100,100,0.15);
$color-medium-tint: #9a9a9a;
$color-medium-tint-rgb: 154,154,154;
$color-medium-tint-rgba: rgba(154,154,154,0.15);
$color-medium-light: #B1B1B1;
$color-medium-light-rgb: 177,177,177;
$color-medium-line: #D8D8D8;
$color-medium-lighter: #F7F7F7;
$color-medium-lightest: #E2E2E2;

$color-light: #ffffff;
$color-light-rgb: 255,255,255;
$color-light-contrast: #000000;
$color-light-contrast-rgb: 0,0,0;
$color-light-shade: #e0e0e0;
$color-light-tint: #f0f0f0;
$color-delta-positive: #6AA15A;
$color-delta-negative: #BA4E4F;

$color-product-primary: #8e2a2a;
$color-product-secondary: #ea6852;
$color-product-tertiary: #9f2842;

$color-superintendence: #009FF2;

$color-disclaimer: #606060;
$color-disabled: rgb(176, 178, 179);

$shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow: 0 6px 12px 0 rgba(0,0,0,0.15);
$container-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);

// Media query
$mobile-small: 360px;
$mobile-medium: 480px;
$mobile-large: 720px;
$mobile-xlarge: 795px;

$desktop-xsmall: 897px;
$desktop-small: 1024px;
$desktop-medium: 1165px;
$desktop-large: 1280px;
$desktop-xlarge: 1440px;

:root {
  --mat-form-field-focus-state-layer-opacity: 0;
  --mat-form-field-hover-state-layer-opacity: 0;
}
