/* ******************* Accessibility ******************* */

@mixin font-sizes($size) {
  &.xsmall {
    font-size: $size - 0.2rem;
    line-height: $size + 0.4rem;
  }
  &.small {
    font-size: $size - 0.1rem;
    line-height: $size + 0.5rem;
  }
  &.medium {
    font-size: $size;
    line-height: $size + 0.6rem;
  }
  &.large {
    font-size: $size + 0.1rem;
    line-height: $size + 0.7rem;
  }
  &.xlarge {
    font-size: $size + 0.2rem;
    line-height: $size + 0.8rem;
  }
}

/* ******************* Info Banner ******************* */

@mixin highlighted-lines($highlighted, $normal) {
  &.highlighted-first {
    .first-line {
      @include font-sizes($highlighted);
    }
    .second-line {
      @include font-sizes($normal);
    }
  }

  &.highlighted-second {
    .first-line {
      @include font-sizes($normal);
    }
    .second-line {
      @include font-sizes($highlighted);
    }
  }
}