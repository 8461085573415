@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./variables.scss";
@import "./mixins.scss";


/* You can add global styles to this file, and also import other style files */

// ----------------------------- Fonts -----------------------------
@font-face {
  font-family: 'helvetica-regular';
  src: url('~/assets/fonts/helvetica-neue-regular.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-bold';
  src: url('~/assets/fonts/helvetica-neue-bold.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-medium';
  src: url('~/assets/fonts/helvetica-neue-medium.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-light';
  src: url('~/assets/fonts/helvetica-neue-light.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

// ----------------------------- General -----------------------------

body {
  margin: 0px;
  font-family: $font-family;
  background-color: $color-light;
}

.general-container {
  max-width: $desktop-large;
  margin: 0 auto;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
  @media (max-width: $desktop-medium) {
    margin-top: 80px;
  }
  .section-banner {
    width: 100%;
  }
  .tabs {
    width: 100%;
    height: auto;
  }
}

// ----------------------------- Icons -----------------------------

.icon {
  background-repeat: no-repeat;
  background-position: center;
}

.i-loading {
  @extend .icon;
  background-image: url("./assets/icons/i-loading.gif");
  height: 400px;
  width: 100%;
}

.i-success {
  @extend .icon;
  background-image: url("./assets/icons/i-success.svg");
  height: 250px;
  width: 350px;
}

.i-lock {
  @extend .icon;
  background-image: url('./assets/icons/i-lock.svg');
  height: 30px;
  width: 30px;
}

.i-warning {
  @extend .icon;
  background-image: url('./assets/icons/i-warning.svg');
  height: 50px;
  width: 50px;
  margin: 10px;
  background-size: contain;
}

.i-pv-white-logo {
  @extend .icon;
  background-image: url('./assets/icons/plan-vital-white.svg');
}

.i-worldwide {
  @extend .icon;
  background-image: url('./assets/icons/i-worldwide.svg');
  height: 30px;
  width: 30px;
}

.i-bar {
  @extend .icon;
  background-image: url('./assets/icons/i-bar.svg');
  height: 30px;
  width: 30px;
}

.i-pig {
  @extend .icon;
  background-image: url('./assets/icons/i-pig.svg');
  height: 30px;
  width: 30px;
}

.i-money-back {
  @extend .icon;
  background-image: url('./assets/icons/i-money-back.svg');
  height: 40px;
  width: 40px;
}

.i-email {
  @extend .icon;
  background-image: url('./assets/icons/i-email.svg');
  height: 40px;
  width: 40px;
}

.i-dollar {
  @extend .icon;
  background-image: url('./assets/icons/i-dollar.svg');
  height: 40px;
  width: 40px;
}

.i-bar-red {
  @extend .icon;
  background-image: url('./assets/icons/i-bar-red.svg');
  height: 40px;
  width: 40px;
}

.i-pig-red {
  @extend .icon;
  background-image: url('./assets/icons/i-pig-red.svg');
  height: 40px;
  width: 40px;
}

.i-taxs {
  @extend .icon;
  background-image: url('./assets/icons/i-taxs.svg');
  height: 40px;
  width: 40px;
}

.i-distribution {
  @extend .icon;
  background-image: url('./assets/icons/i-distribution.svg');
  height: 40px;
  width: 40px;
}

.i-bh {
  @extend .icon;
  background-image: url('./assets/icons/i-bh.svg');
  height: 40px;
  width: 74px;
}

.i-account {
  @extend .icon;
  background-image: url('./assets/icons/i-account.svg');
  height: 26px;
  width: 26px;
}

.i-menu {
  @extend .icon;
  background-image: url('./assets/icons/i-menu.svg');
  height: 17px;
  width: 22px;
}

.i-phone {
  @extend .icon;
  background-image: url('./assets/icons/i-phone.svg');
  height: 21px;
  width: 21px;
}

.i-whatsapp {
  @extend .icon;
  background-image: url('./assets/icons/i-whatsapp.svg');
  height: 25px;
  width: 20px;
}

.i-instagram {
  @extend .icon;
  background-image: url('./assets/icons/i-instagram.svg');
  height: 25px;
  width: 20px;
}

.i-linkedin {
  @extend .icon;
  background-image: url('./assets/icons/i-linkedin.svg');
  height: 25px;
  width: 20px;
}

.i-youtube {
  @extend .icon;
  background-image: url('./assets/icons/i-youtube.svg');
  height: 25px;
  width: 25px;
}

.i-glove {
  background-repeat: no-repeat;
  background-image: url("~/assets/icons/i-glove.svg");
}

.i-glove-white {
  @extend .icon;
  background-repeat: no-repeat;
  background-image: url("~/assets/icons/i-glove-white.svg");
}

.i-arrow-up-white {
  @extend .icon;
  background-repeat: no-repeat;
  background-image: url("~/assets/icons/i-arrow-up-white.svg");
  height: 12px;
  width: 21px;
}

.i-arrow-down-white {
  @extend .icon;
  background-repeat: no-repeat;
  background-image: url("~/assets/icons/i-arrow-down-white.svg");
  height: 12px;
  width: 21px;
}

// ----------------------------- Images -----------------------------

.image {
  @extend .icon;
  background-size: cover;
}

.logo-pv-clear {
  @extend .icon;
  background-image: url("./assets/img/logo_pv_clear.png");
  height: 100%;
  width: 100%;
}

.logo-pv-white {
  @extend .icon;
  background-image: url("./assets/img/logo-pv-white.svg");
  height: 65px;
  width: 150px;
  margin: 10px 0;
}

.img-superintendence-logo {
  background-image: url('./assets/img/superintendence_logo.png');
  background-repeat: no-repeat;
}

// ----------------------------- Buttons -----------------------------

.button {
  @include font-sizes(1rem);
  border: none;
  background-color: transparent;
  font-family: $font-family;
  color: $color-light;

  &:focus { outline: none; }

  &.primary {
    text-align: center;
    background-color: $color-primary;
    font-weight: bold;
    padding: 10px 62px;
    border-radius: 32px;
    cursor: pointer;
    transition: all .4s;
  }
  &:disabled {
    background-color: $color-disabled;
    cursor: not-allowed;
  }

}

// ----------------------------- Reset -----------------------------

p {
  margin: 0;
  text-align: left;
}

// ----------------------------- Texts -----------------------------

.title {
  @include font-sizes(1.7rem);
  font: normal $font-family;
  color: $color-primary;
  letter-spacing: 0.94px;

  &.form {
    @include font-sizes(1.2rem);
    font-family: $font-family-bold;
    letter-spacing: 0.2px;
  }
}
.description {
  @include font-sizes(1.1rem);
  font: normal $font-family;
  color: $color-medium;
  letter-spacing: 0.7px;
  text-align: justify;
}
.text {
  @include font-sizes(0.875rem);
  font: normal $font-family;
  color: $color-medium;
  text-align: justify;
}
.disclaimer {
  @include font-sizes(0.875rem);
  width: 100%;
  font-family: $font-family;
  line-height: 8px;
  color: $color-disclaimer;
  a:link {
    color: $color-primary;
    text-decoration: none;
  }
  a:visited { color: $color-primary; }
}

// ----------------------------- Forms -----------------------------

.mat-mdc-form-field {
  @include font-sizes(1rem);
}
.mat-mdc-form-field-focus-overlay{
  background-color: unset;
}
.mat-mdc-form-field .mdc-text-field {
  padding: unset;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset !important;
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: $color-dark !important;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  @include font-sizes(1rem);
  font-family: $font-family-medium !important;
  color: $color-dark !important;
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  @include font-sizes(1rem);
  font-family: $font-family-medium !important;
  color: $color-dark !important;
}
.mat-mdc-form-field {
  @include font-sizes(1rem);
  margin-bottom: 10px;
  min-height: 70px;
  .mat-input-element {
    @include font-sizes(1rem);
    line-height: 16px !important;
    font-family: $font-family-light;
    caret-color: $color-dark;
  }
  .mat-form-field-placeholder {
    @include font-sizes(1rem);
    line-height: 19px;
    font-family: $font-family-light;
    color: $color-dark;
    opacity: 0.7;
  }
  &.mat-form-field-invalid .mat-form-field-label, &.mat-focused .mat-form-field-label, .mat-form-field-label {
    font-family: $font-family-medium;
    color: $color-dark;
  }
  &.mat-focused .mat-form-field-ripple { background-color: $color-dark; }
  &.mat-form-field-invalid .mat-form-field-ripple { background-color: $color-primary; }
  .i-validator {
    display: block;
    width: 20px;
    height: 20px;
    background: url('./assets/icons/i-valid.svg') center/contain no-repeat;
  }
  &.mat-form-field-invalid .i-validator { background-image: url('./assets/icons/i-invalid.svg'); }

  &.ng-untouched .i-validator { display: none; }

  &.mat-form-field-disabled .i-validator { display: none; }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-error {
    @include font-sizes(0.875rem);
    line-height: 20px !important;
    font-family: $font-family-bold;
    color: $color-primary;
  }

  &.secondary {
    &.mat-form-field-invalid .mat-form-field-label, &.mat-focused .mat-form-field-label, .mat-form-field-label {
      @include font-sizes(1.1rem);
      font-family: $font-family-medium;
    }
  }
}

// ----------------------------- Select -----------------------------

.mat-select {
  @include font-sizes(1rem);
  width: 100%;
  background-color: transparent;
  font-family: $font-family-light;
  color: $color-medium-tint;
  border: none;
  &:focus {
    outline: none;
  }
}
.mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, .12) !important;
}
.mat-select-value {
  color: $color-dark;
}
.mat-option-text {
  color: $color-dark;
}
.mat-select-arrow {
  color: $color-dark;
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: $color-dark;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: 24px;
}

// ----------------------------- Links -----------------------------

a.disabled {
  &.btn {
    color: $color-light;
    background-color: $color-disabled;
    pointer-events: none;
    opacity: unset;
  }
}
a:link {
  @include font-sizes(0.8rem);
  color: $color-primary;
  font-family: $font-family;
  line-height: 26px;
  text-decoration: none;
  &.btn {
    @include font-sizes(0.8rem);
    color: $color-light;
    height: 24px;
    padding: 10px 44px;
    text-align: center;
  }
}

a:visited { color: $color-primary; }

a:focus { outline: none; }

// Recaptcha
.grecaptcha-badge {
  visibility: hidden;
}

.version {
  position: fixed;
  bottom: 20px;
  font-family: $font-family;
  font-size: 12px;
  right: 5px;
  padding: 7px 7px 7px 2px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  background: white;
  border-radius: 0 5px 5px 0;
}
